<template>
	<el-card class="box-card">
		<el-form :model="securityForm" :rules="formRules" ref="form" label-position="top" label-width="100px">
			<el-card shadow="never">
				<div slot="header" class="pass-header">
					<div>
						<strong>{{$t('account.profile.password-header')}}</strong>
					</div>
					<div>
						<el-button type="primary" icon="el-icon-check" @click="save">
							{{$t('general.save')}}
						</el-button>
					</div>
				</div>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
						<el-form-item :label="$t('account.profile.password-label1')" prop="password">
							<el-input type="password" v-model="password"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
						<el-form-item :label="$t('register.label.confirm-password')" prop="repeatPassword">
							<el-input type="password" v-model="repeatPassword"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
			<div class="separator"></div>
			<el-card shadow="never">
				<div slot="header">
					<strong>{{$t('account.profile.close-account')}}</strong>
				</div>
				<el-row :gutter="20">
					<Box :type="'info'" :active="true" style="margin-top:0">
						<div slot="box-body">
							<ul class="rawUl">
								<li v-html="$t('account.profile.terminal-doc-info')"></li>
							</ul>
						</div>
					</Box>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<el-form-item>
							<el-button class="b-c-ca" :disabled="parseInt(terminalDoc) === 1" type="primary"
								size="small" icon="el-icon-error" plain @click.native="closeAccount">
								{{$t('account.profile.close-account')}}</el-button>
							<span v-if="parseInt(terminalDoc) === 1"
								class="danger-text">{{$t('account.profile.terminal-doc-generated')}}</span>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
		</el-form>
	</el-card>
</template>

<script>
	import i18n from '@/lang';
	const Box = () => import('vue-info-box-element');
	import {
		call,
		get,
		sync
	}
	from 'vuex-pathify';
	export default {
		components: {
			Box
		},
		data() {
			const validateConfirmPassword = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(i18n.t('register.error.confirm-password')));
				} else if (value !== this.password) {
					callback(new Error(i18n.t('register.error.confirm-password')));
				} else {
					callback();
				}
			};
			return {
				formRules: {
					password: [{
							required: true,
							message: i18n.t('register.error.password'),
							trigger: 'blur'
						},
						{
							min: 6,
							max: 32,
							message: i18n.t('register.error.password-length'),
							trigger: 'blur'
						}
					],
					repeatPassword: [{
						required: true,
						validator: validateConfirmPassword,
						trigger: 'blur'
					}],
				}
			};
		},
		computed: {
			...sync('account/informations@security', {
				password: 'password',
				repeatPassword: 'repeatPassword',
				terminalDoc: 'terminalDoc'
			}),
			securityForm: get('account/informations@security')
		},
		methods: {
			getTerminalDocStatus: call('account/GetAffiliateTerminalDocStatus'),
			changeAffiliatePassword: call('account/ChangeAffiliatePassword'),
			closeAffiliateAccount: call('account/CloseAccount'),
			async save() {
				this.$refs.form.validate(async (valid) => {
					if (!valid) return false;
					try {
						await this.changeAffiliatePassword();
						this.sbMsg({
							type: 'success',
							message: this.$t('general.changes-saved')
						});
					} catch (err) {
						this.$reportToSentry(err, {
							extra: {
								fn: 'changeAffiliatePassword',
								params: this.$store.get('account/informations@security')
							}
						});
					}
				});
			},
			async closeAccount() {
				this.$confirm(
					this.$t('account.profile.terminal-doc-confirm'),
					'Atentie', {
						confirmButtonText: this.$t('general.send'),
						cancelButtonText: this.$t('general.cancel'),
						type: 'info'
					}).then(async () => {
					try {
						const res = await this.closeAffiliateAccount();
						if (typeof res.message === 'undefined') {
							throw new Error();
						}
						if (res.message.problems != null) {
							let message = '<ul>';
							res.message.problems.forEach((problem) => {
								message += `<li><strong>${problem}</strong></li>`;
							});
							message += '</ul>';
							this.$alert(message, 'Probleme', {
								confirmButtonText: 'OK',
								type: 'error',
								dangerouslyUseHTMLString: true
							});
							return false;
						}
						if (res.message.message != null) {
							this.$alert(res.message.message, 'Informatie', {
								confirmButtonText: 'OK',
								type: 'success',
							});
							await this.getTerminalDocStatus();
							return true;
						}
					} catch (e) {
						this.sbMsg({
							type: 'error',
							message: 'Eroare generala. Contactati departamentul tehnic'
						});
					}
				}).catch(() => {});
			}
		},
		async mounted() {
			try {
				await this.getTerminalDocStatus();
			} catch (err) {
				this.$reportToSentry(err, {
					extra: {
						fn: 'getTerminalDocStatus'
					}
				});
			}
		}
	};
</script>

<style scoped>
	.pass-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	::v-deep div.el-card__header {
		padding: 5px 20px !important;
	}
</style>